import "./src/css/index.css"
import "./src/css/markdown-styles.css";
import "./src/css/prism-theme.css"

// in gatsby-browser.js
export function shouldUpdateScroll(prevRouterProps, { location }) {
  window.scrollTo(0, 0)
  const body = document.getElementsByTagName('body')[0]
  body.scrollTop = 0
  return false
}